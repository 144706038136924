import React from "react";
import { color } from "@rimo/ui-old";
import styled from "styled-components";
import { useSearchParams } from "next/navigation";
import { LocaleLink } from "../../LocaleLink";

export const ButtonBase = styled.span`
  font-size: 14px;
  font-weight: 600;
  border-radius: 10000px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  padding: 15px 20px;
  text-align: center;
  word-break: keep-all;
  height: fit-content;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
`;

const StyledPrimaryButton = styled(ButtonBase)`
  color: #fff;
  background-color: ${color.primary};
`;

export const StyledSecondaryButton = styled(ButtonBase)`
  color: ${color.primary};
  background-color: #fff;
  transition: all 200ms;
`;
type Props = {
  link?: string;
  className?: string;
  hash?: string;
  query?: string;
};

export const PrimaryButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const searchParams = useSearchParams();
  return (
    <LocaleLink
      href={{ pathname: props.link, hash: props.hash, query: props.query || searchParams?.toString() }}
      passHref
    >
      <StyledPrimaryButton className={props.className}>{props.children}</StyledPrimaryButton>
    </LocaleLink>
  );
};

export const SecondaryButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const searchParams = useSearchParams();
  return (
    <LocaleLink href={{ pathname: props.link, hash: props.hash, query: searchParams?.toString() }} passHref>
      <StyledSecondaryButton className={props.className}>{props.children}</StyledSecondaryButton>
    </LocaleLink>
  );
};
