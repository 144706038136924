import styled, { css } from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export type FormData = {
  lastName: string;
  firstName: string;
  companyName: string;
  employeeCount: string;
  post: string;
  position: string;
  postOther?: string;
  mail: string;
  phone: string;
  inflow: string;
  inflowOther?: string;
  freeText: string;
  agree: boolean;
  planName?: string;
  mediaTitle?: string;
  utmSource?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmCampaign?: string;
  affiliateCode?: string;
  affiliateOrderNumber?: string;
};

export const MOBILE_BREAKPOINT = 960;
export const X_LARGE_BREAKPOINT = 1680;
export const SlimContent = styled.div`
  box-sizing: border-box;
  @media (min-width: ${`${X_LARGE_BREAKPOINT}px`}) {
    margin: 0 auto;
    width: 1200px;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) and (max-width: ${`${X_LARGE_BREAKPOINT - 1}px`}) {
    width: 100%;
    padding-left: 160px;
    padding-right: 160px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
`;
export const WideContent = styled.div`
  box-sizing: border-box;
  @media (min-width: ${`${X_LARGE_BREAKPOINT}px`}) {
    margin: 0 auto;
    width: 1440px;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) and (max-width: ${`${X_LARGE_BREAKPOINT - 1}px`}) {
    width: 100%;
    padding: 0 80px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 0 16px;
    width: 100%;
  }
`;
export const Lead = styled.h2`
  font-weight: 700;
  text-align: center;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 40px;
    line-height: 56px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 28px;
    line-height: 40px;
  }
`;
export const SubLead = styled.h3`
  margin-top: 16px;
  font-weight: 400;
  text-align: center;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 15px;
    line-height: 24px;
  }
`;
export const inputBase = css`
  background: white;
  padding: 4px 10px;
  font-size: 16px;
  line-height: 28px;
  border: none;
  outline: none;
  border: solid 2px white;
  box-sizing: border-box;
  color: #222;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    width: 100%;
    border-radius: 4px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 100%;
    border-radius: 8px;
  }
`;
export const InputButton = styled.button<{ $error: boolean; $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${inputBase}
  ${(p) => p.$error && `border: solid 1px ${p.theme.vars.palette.primary.main};`}
  ${[(p) => (p.$selected ? `color: #222;` : `color: rgba(0, 0, 0, 0.4);`)]}
  cursor: pointer;
`;
export const Chevron = styled(ArrowDropDownIcon)<{ $isOpen: boolean }>`
  color: #666;
  ${(p) => p.$isOpen && `transform: rotate(180deg);`}
`;
export const DropdownList = styled.ul`
  border-radius: 4px;
  overflow: scroll;
  max-height: 250px;
  position: absolute;
  padding: 8px 0;
  background: #fff;
  box-shadow:
    0px 4px 12px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.02);
  width: 100%;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 90%;
  }
`;
export const DropdownItem = styled.li<{ selected: boolean }>`
  ${inputBase}
  border-radius: 0;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`;
export const ErrorMessage = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.vars.palette.primary.main};
  margin-top: 6px;
`;
export const InputWrapper = styled.div`
  padding: 6px 0;
`;
export const Input = styled.input<{ $error: boolean }>`
  &[type="text"] {
    display: block;
    ${inputBase}
    ${(p) => p.$error && `border: solid 1px ${p.theme.vars.palette.primary.main};`}
    border: solid 2px transparent;
    &:focus {
      outline: none;
      border-color: ${(p) => p.theme.vars.palette.primary.main};
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;
export const MobileBr = styled.br`
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    display: none;
  }
`;
