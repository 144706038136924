import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import { Lead, MOBILE_BREAKPOINT, MobileBr, SlimContent, SubLead } from "./shared";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CASE_LIST = [
  {
    title: "議事録作成時間半減で社内の必須ツールに",
    desc: "文字起こし精度の良さに加えて、クラウドサービスでありながら セキュリティがしっかりしているというのは導入の決定打となりました。 役員会議の議事録作成時間が半減し、社内でも「Rimoがないと困る」と言われています。",
    companyName: "井村屋グループ株式会社",
    companyLogo: "/assets/about_genelic/proceedings/case_imura.png",
    tag: ["議事録作成"],
  },
  {
    title: "シンプルな画面の使い勝手と価格が決め手",
    desc: "議事録作成を効率化したく、文字起こしサービス導入の検討を始めました。数社検討した中で、コストパフォーマンスが最も高かったため導入に至りました。感覚的に使えるシンプルで使い勝手のよいUIも決め手の一つでした。",
    companyName: "パーソルホールディングス株式会社",
    companyLogo: "/assets/about_genelic/proceedings/case_persol.png",
    tag: ["議事録作成"],
  },
  {
    title: "ライターへのインタビューの文字起こし共有が手軽に実現",
    desc: "従来より低コストかつ高速に文字起こしができるため、速報性が必要な記事の制作にも重宝しています。社外ライターさんへの執筆依頼時、Rimo Voice の議事録データを共 有したら、作業がしやすかったと感謝されました。",
    companyName: "株式会社翔泳社",
    companyLogo: "/assets/about_genelic/proceedings/case_se.png",
    tag: ["インタビュー・ライティング"],
  },
];

export const CaseSection: React.FC = () => {
  const { customT } = useClientTranslation();

  return (
    <Content>
      <Lead>
        {customT("多様な業界・組織で")}

        <MobileBr />
        {customT("の導入事例")}
      </Lead>
      <SubLead>{customT("業種や組織規模に関わらず、多くの企業様での会議と議事録を変革しています")}</SubLead>
      <CaseList>
        <CaseTopItem>
          <CaseImage src="/assets/about_genelic/proceedings/case_image.jpg" />
          <RightBlock>
            <TopBlock>
              <AvatarWrapper>
                <Avatar src={customT(CASE_LIST[0].companyLogo)} />
              </AvatarWrapper>
              <CompanyInfo>
                <CompanyName>{customT(CASE_LIST[0].companyName)}</CompanyName>
                <TagList>
                  {CASE_LIST[0].tag.map((t) => (
                    <Tag key={t}>{customT(t)}</Tag>
                  ))}
                </TagList>
              </CompanyInfo>
            </TopBlock>
            <Title>{customT(CASE_LIST[0].title)}</Title>
            <Description>{customT(CASE_LIST[0].desc)}</Description>
            <a href="https://rimo.app/case-studies/Z24aLo4BOZW2yZ8QzC8d" target="_blank" rel="noreferrer">
              <ReadMore>
                {customT("続きを読む")}
                <ArrowForwardIosIcon />
              </ReadMore>
            </a>
          </RightBlock>
        </CaseTopItem>
        {CASE_LIST.slice(1).map((i) => (
          <CaseItem key={i.title}>
            <TopBlock>
              <AvatarWrapper>
                <Avatar src={customT(i.companyLogo)} />
              </AvatarWrapper>
              <CompanyInfo>
                <CompanyName>{customT(i.companyName)}</CompanyName>
                <TagList>
                  {i.tag.map((t) => (
                    <Tag key={t}>{customT(t)}</Tag>
                  ))}
                </TagList>
              </CompanyInfo>
            </TopBlock>
            <Title>{customT(i.title)}</Title>
            <Description>{customT(i.desc)}</Description>
          </CaseItem>
        ))}
      </CaseList>
      <ViewOtherWrapper>
        <a href="https://rimo.app/case-studies" target="_blank" rel="noreferrer">
          <ViewOther>
            {customT("ほかの導入事例をみる")}

            <ArrowForwardIosIcon />
          </ViewOther>
        </a>
      </ViewOtherWrapper>
    </Content>
  );
};
const Content = styled(SlimContent)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 40px;
    padding-top: 48px;
    padding-bottom: 32px;
    max-width: none;
  }
`;
const CaseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  -ms-overflow-style: none;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 48px;
    padding: 24px 0;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 16px;
    padding: 24px 36px;
    margin-left: -16px;
    width: calc(100vw - 72px);
  }
`;
const Avatar = styled.img`
  grid-area: avatar;
  width: 30px;
`;
const CaseItem = styled.div`
  box-sizing: border-box;
  &:last-child {
    margin-right: 0;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 56px;
    margin-right: 40px;
    width: calc(50% - 20px);
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
    flex-shrink: 0;
    padding: 12px 0;
  }
  &:nth-child(3) {
    ${Avatar} {
      width: 25px;
    }
  }
`;
const Title = styled.h2`
  font-weight: 700;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 16px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.342857px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 16px;
    font-size: 22px;
    line-height: 32px;
  }
`;
const Description = styled.p`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 8px;
    font-size: 15px;
    line-height: 24px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 16px;
    font-size: 15px;
    line-height: 24px;
  }
`;
const TopBlock = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "avatar text"
    "tags tags";
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 8px;
  }
`;
const AvatarWrapper = styled.div`
  background: white;
  grid-area: avatar;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #f5f5f5;
`;
const CompanyInfo = styled.div`
  grid-area: text;
`;
const CompanyName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;
const TagList = styled.div`
  grid-area: tags;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const Tag = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;
const CaseImage = styled.img`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 50%;
    border-radius: 8px;
    margin-right: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
  }
`;
const RightBlock = styled.div``;

const CaseTopItem = styled(CaseItem)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
const ReadMore = styled.button`
  color: ${(p) => p.theme.vars.palette.error.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 140px;
  height: 40px;
  background: rgba(255, 175, 0, 0.24);
  border-radius: 100px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  svg {
    width: 18px;
    margin-left: 8px;
  }
`;
const ViewOther = styled.button`
  color: ${(p) => p.theme.vars.palette.text.primary};
  background: ${(p) => p.theme.vars.palette.background.light};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 44px;
  border-radius: 100px;
  border: none;
  outline: none;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  svg {
    width: 18px;
    position: absolute;
    right: 12px;
  }
`;
const ViewOtherWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
