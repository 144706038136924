import useMediaQuery from "@mui/material/useMediaQuery";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { useEffect, useState } from "react";

export const useResponsive = () => {
  const [isPC, setIsPC] = useState(true);
  const isPCQuery = useMediaQuery(`(min-width: ${MOBILE_BREAKPOINT}px)`);

  useEffect(() => {
    setIsPC(isPCQuery);
  }, [isPCQuery]);

  return {
    isPC,
    isMobile: !isPC,
  };
};
